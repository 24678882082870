@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.logo {
  width: 90px;
}

.pd-con {
  padding: 50px 0 100px 0;
}

.calendar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-photo {
  width: 80%;
}

a {
  text-decoration: none;
}

.img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container img {
  display: block;
  max-width: 100%;
  height: auto;
  filter: brightness(70%);
}

.img-container .caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 10px;
  font-size: 50px;
  text-align: center;
}

.dr-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 50px;
  margin-bottom: 50px;
}

.dr-section img {
  width: 40%;
  border-radius: 10px;
}

.dr-section .text-content {
  width: 45%;
}
.dr-section h3 {
  text-align: center;
}

.vision h3,
.mision h3,
.dr-section .text-content h3,
.social-contact h3 {
  font-size: 2.6rem;
  font-weight: 400;
  padding-bottom: 10px;
}
.vision p,
.mision p,
.dr-section .text-content p {
  font-size: 1.2rem;
  font-weight: 200;
}

/* Provimet */
.text-container {
  width: 100%;
  padding: 40px 80px;
}

.text-container .main-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.text-container .main-cont img {
  width: 50%;
  padding-bottom: 20px;
}

.text-container .main-cont h4 {
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 1.4rem;
}
.text-container .main-cont p {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  padding-top: 20px;
}

.text-container h3 {
  text-align: center;
}

/* historiku */

.text-about {
  position: relative;
  padding: 40px 80px;
}

.text-about {
  width: 100%;
  padding: 70px;
}
.text-about h3 {
  text-align: center;
  font-weight: 400;
  padding-bottom: 10px;
  font-size: 2rem;
}
.text-about p {
  font-size: 14px;
  font-weight: 300;
}

/* Footer */
/* Footer Contact */
footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 70px 40px;
}

footer li {
  list-style: none;
  font-size: 14px;
}

.social-contact {
  color: #fff;
}

footer .social-contact h3 {
  font-size: 20px;
  font-weight: 500;
}

.social-contact,
.google-map {
  width: 30%;
  text-align: left;
}
.google-map {
  height: 300px;
  width: 300px;
  border-radius: 10px;
}

.social-contact h3,
.social-contact li {
  padding-bottom: 13px;
}

.social-contact p {
  font-size: 1.4rem;
  font-weight: 300;
}
.social-contact li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 300;
}
.social-contact li a:hover {
  color: rgb(150, 23, 23);
}
.social-contact li i {
  padding-right: 5px;
}
/* Rregullorja  */

/* Copiright */
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: #000;
}

.copyright p {
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
}

@media (max-width: 400px) {
  .bread-anchor {
    font-size: 9px;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 50px;
  }
  .img-container .caption {
    font-size: 30px;
  }
  .school-breadcrumb li {
    font-size: 0.55rem;
  }

  .dr-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dr-section img,
  .dr-section .text-content {
    width: 100%;
  }
  .dr-section .text-content p {
    font-size: 11px;
    line-height: 24px;
    margin: 10px;
  }

  .dr-section .text-content h3 {
    font-size: 18px;
    margin-top: 10px;
  }

  .nxenes th,
  .nxenes td {
    font-size: 9px;
  }

  /* provimet */
  .text-container {
    padding: 10px 15px;
  }
  .text-container .main-cont {
    padding: 0 10px;
  }
  .text-container .main-cont h4 {
    padding: 4px 0;
    font-size: 8px;
  }

  .text-container h5 {
    padding: 0 3px;
    font-size: 9px;
    text-align: center;
  }

  .text-container .main-cont p,
  .text-container ol li {
    font-size: 8px;
  }
  .text-container h3 {
    font-size: 1rem;
  }

  /* historiku */

  .text-about {
    position: relative;
    padding: 40px 80px;
  }

  .text-about {
    width: 100%;
    padding: 20px;
  }
  .text-about h3 {
    text-align: center;
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 17px;
  }
  .text-about p {
    font-size: 10px;
    font-weight: 300;
  }

  /* Footer */
  footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .google-map,
  .social-contact {
    width: 100%;
  }
  footer .social-contact h3 {
    font-size: 14px;
  }
  .social-contact li {
    font-size: 10px;
  }

  /* copyright */
  .copyright p {
    font-size: 10px;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .img-container .caption {
    font-size: 40px;
  }
  .logo {
    width: 60px;
  }

  .school-breadcrumb li {
    font-size: 14px;
  }

  .dr-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dr-section img,
  .dr-section .text-content {
    width: 100%;
  }
  .dr-section .text-content p {
    font-size: 20px;
    line-height: 24px;
    margin: 10px;
  }

  .nxenes th,
  .nxenes td {
    font-size: 11px;
  }

  .table-container h1 {
    font-size: 20px;
    padding-bottom: 10px;
  }
  .table-info .text-content h4 {
    font-size: 18px;
  }
  .table-info .text-content p {
    font-size: 12px;
  }

  /* Provimet */

  .text-container {
    padding: 3px 7px;
  }

  .text-container .main-cont {
    padding: 0 13px;
  }
  .text-container h5 {
    padding: 0 3px;
    font-size: 12px;
    text-align: center;
  }

  .text-container .main-cont h4 {
    padding: 2px 0;
    font-size: 13px;
  }

  .text-container .main-cont p {
    font-size: 12px;
  }
  .text-container h3 {
    font-size: 16px;
  }

  .text-container ol li {
    font-size: 10px;
  }

  /* historiku */

  .text-about {
    position: relative;
    padding: 40px 80px;
  }

  .text-about {
    width: 100%;
    padding: 30px;
  }
  .text-about h3 {
    text-align: center;
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 24px;
  }
  .text-about p {
    font-size: 14px;
    font-weight: 300;
  }

  /* Footer */
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .social-contact {
    width: 100%;
    padding-bottom: 20px;
  }
  .social-contact h3 {
    font-size: 17px;
  }
  .social-contact li {
    font-size: 14px;
  }
  .google-map {
    width: 100%;
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .img-container .caption {
    font-size: 45px;
  }
  .logo {
    width: 70px;
  }

  .nxenes th,
  .nxenes td {
    font-size: 14px;
  }
  .social-contact h3 {
    font-size: 1.5rem;
  }
  .social-contact li {
    font-size: 0.6rem;
  }

  .table-container h1 {
    font-size: 27px;
    padding-bottom: 10px;
  }

  .dr-section .text-content p {
    font-size: 15px;
  }
  .text-container {
    padding: 3px 7px;
  }
  .text-container .main-cont h4 {
    padding: 4px 0;
    font-size: 22px;
  }

  .text-container h5 {
    padding: 0 3px;
    font-size: 17px;
    text-align: center;
  }

  .text-container .main-cont h4 {
    padding: 2px 0;
    font-size: 20px;
    font-weight: 400;
  }

  .text-container ol li {
    font-size: 13px;
  }
}
